import React from 'react';
import { Container } from 'react-bootstrap';

import './Projects.css';

function Projects() {
  return (
    <Container className='py-4'>
      <h2>Projects</h2>
      
    </Container>
  );
}

export default Projects;
